import axios from 'axios'

const API = axios.create({
  baseURL: window.location.hostname.includes("flexvastgoedcrm")
  ? "https://server.kantoorflex.nl/api/rest/v2"
  : "https://server.kantoorflex.nl/api/rest/v2",
  timeout: 30000,
});


export default {

  async  makeAppointment(data) {
    try {
      const response = await API.post('appointment', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getCategories() {
    try {
      const response = await API.get('appointment');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  //Owner
  async getOwnerPendingAppointments() {
    try {
      const response = await API.get(`/appointment/owner/office/pending/appointments`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

  async getOwnerCancelledAppointments() {
    try {
      const response = await API.get(`/appointment/owner/office/cancelled/appointments`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },


  async getOwnerApprovedAppointments() {
    try {
      const response = await API.get(`/appointment/owner/office/approved/appointments`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },


};
