<template>
  <div>
    <v-container style="padding-top: 5rem">
      <div class="text-center mb-8">
        <v-btn color="deep-purple" outlined @click="active = !active">
          {{ active ? "Menu verbergen" : "Menu laten zien" }}
        </v-btn>
      </div>
      <v-bottom-navigation style="margin-bottom:15px" v-if="active">
        <v-btn
          :color="activeElement === 'Pending' ? '' : ''"
          @click="activeElement = 'Pending'"
        >
          <span>Wacht op goedkeuring</span>

          <v-icon :color="activeElement === 'Pending' ? 'blue lighten-1' : ''"
            >gavel</v-icon
          >
        </v-btn>

        <v-btn
          :color="activeElement === 'Approved' ? '' : ''"
          @click="activeElement = 'Approved'"
        >
          <span>Goedgekeurd</span>

          <v-icon
            :color="activeElement === 'Approved' ? 'green lighten-1' : ''"
          >
            mdi-checkbox-marked-circle</v-icon
          >
        </v-btn>

        <v-btn
          :color="activeElement === 'Cancelled' ? '' : ''"
          @click="activeElement = 'Cancelled'"
        >
          <span>Gegeannuleerd</span>

          <v-icon :color="activeElement === 'Cancelled' ? 'red darken-1' : ''"
            >mdi-cancel</v-icon
          >
        </v-btn>
      </v-bottom-navigation>

      <v-card v-if="activeElement === 'Pending'">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Boeking opzoeken"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="pendingAppointments"
          :search="search"
        >
          <template #[`item.appointment_date`]="{item}">
            {{
              item.appointment_date
                ? new Date(item.appointment_date).toLocaleDateString()
                : ""
            }}
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              color="red"
              @click="approveOrCancelBooking(item.appointment_id, 'cancelled')"
            >
              mdi-close-circle
            </v-icon>

            <v-icon
              small
              class="mr-2"
              color="green"
              @click="approveOrCancelBooking(item.appointment_id, 'approved')"
            >
              mdi-check-circle-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-card v-if="activeElement === 'Approved'">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Boeking opzoeken"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="approvedAppointments"
          :search="search"
        >
          <template #[`item.appointment_date`]="{item}">
            {{
              item.appointment_date
                ? new Date(item.appointment_date).toLocaleDateString()
                : ""
            }}
          </template>
        </v-data-table>
      </v-card>

      <v-card v-if="activeElement === 'Cancelled'">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Boeking opzoeken"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="cancelledAppointments"
          :search="search"
        >
          <template #[`item.appointment_date`]="{item}">
            {{
              item.appointment_date
                ? new Date(item.appointment_date).toLocaleDateString()
                : ""
            }}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import apiOwner from "../api/apiAppointment";
import axios from "axios";
export default {
  data() {
    return {
      active: true,
      activeElement: "Pending",
      search: "",
      pendingAppointments: [],
      approvedAppointments: [],
      cancelledAppointments: [],
      headers: [
        {
          text: "Reserveringsnummer",
          value: "reservation_number",

          sortable: false,
        },

        {
          text: "Ingepland voor",
          value: "appointment_date",
          sortable: true,
        },

        {
          text: "van",
          value: "from_time",

          sortable: false,
        },

        {
          text: "tot",
          value: "to_time",
          sortable: false,
        },

        {
          text: "dagdeel",
          value: "per_dagdeel",
          sortable: false,
        },

        {
          text: "personen",
          value: "aantal_personen",
          sortable: false,
        },

        {
          text: "status",
          value: "status",
          sortable: false,
        },

        {
          text: "Actie",
          value: "Actions",
          sortable: false,
        },
      ],
    };
  },

  async mounted() {
    apiOwner.getOwnerPendingAppointments().then((data) => {
      this.pendingAppointments = data;
    });

    apiOwner.getOwnerApprovedAppointments().then((data) => {
      this.approvedAppointments = data;
    });

    apiOwner.getOwnerCancelledAppointments().then((data) => {
      this.cancelledAppointments = data;
    });
  },
  methods: {
    async statusOrCancelApproved(appointment_id, status) {
      const appointment = this.pendingAppointments.find(
        (appointment) => appointment.appointment_id === appointment_id
      );
      const reservationNumber = appointment.reservation_number;
      await axios.put(
        `https://server.kantoorflex.nl/api/rest/v2/appointment/owner/office/appointments/update/${appointment_id}`,
        {
          status: status,
          reservation_number: reservationNumber,
        }
      );
      this.$swal.fire(
        "Gewijzigd!",
        `Reservatienummer: ${reservationNumber}`,
        "success"
      );
      await apiOwner.getPendingAppointments().then((data) => {
        this.pendingAppointments = data;
      });

      await apiOwner.getOwnerApprovedAppointments().then((data) => {
        this.approvedAppointments = data;
      });

      await apiOwner.getOwnerCancelledAppointments().then((data) => {
        this.cancelledAppointments = data;
      });
    },

    approveOrCancelBooking(appointment_id, status) {
      let title =
        status === "approved"
          ? "Bent u zeker dat u de reservering wil goedkeuren?"
          : "Bent u zeker dat u de reservering wil annuleren?";
      this.$swal
        .fire({
          title: title,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ja",
          cancelButtonText: "Annuleer",
          customClass: {
            title: "swal-title",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.statusOrCancelApproved(appointment_id, status);
            this.$swal.fire(
              status === "approved" ? "Goedgekeurd!" : "Geannuleerd!",
              status === "approved"
                ? "De reservering is goedgekeurd."
                : "De reservering is geannuleerd.",
              "success"
            );
          }
        });
    },
  },
};
</script>

<style scoped>
.swal-title {
  font-size: 18px;
}
</style>
