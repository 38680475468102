<template>
  <v-container>
    <div>
      <v-row>
        <v-col sm="4" md="3" xl="12">
          <v-card max-width="250">
            <v-img
              src="@/assets/Plugins/outlook.png"
              height="200px"
              contain
            ></v-img>

            <v-card-title>
              Outlook plug-in
            </v-card-title>

            <v-card-subtitle>
              Klik om de gegevens in te vullen voor het maken van een koppeling
              met jou agenda.
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                style="background-color:#2196F3;color:white"
                @click="alert()"
              >
                Activeren
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col sm="4" md="3" xl="12">
          <v-card max-width="250">
            <v-img
              src="@/assets/Plugins/moneybird.png"
              height="200px"
              contain
            ></v-img>

            <v-card-title>
              Moneybird plug-in
            </v-card-title>

            <v-card-subtitle>
              Klik hier om de facturen automatisch te laten aanmaken in jouw
              moneybird.
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                style="background-color:#2196F3;color:white"
                @click="alert()"
              >
                Activeren
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col sm="4" md="3" xl="12">
          <v-card max-width="250">
            <v-img
              src="@/assets/Plugins/snelstart.png"
              height="200px"
              contain
            ></v-img>

            <v-card-title>
              Snelart plug-in
            </v-card-title>

            <v-card-subtitle>
              Klik hier om de facturen automatisch te laten aanmaken in jou
              snelstart.
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                style="background-color:#2196F3;color:white"
                @click="alert()"
              >
                Activeren
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),

  methods: {
    alert() {
      this.$swal.fire("Error!", "Deze module niet beschikbaar", "error");
    },
  },
};
</script>

<style scoped>
.spacer {
  margin-top: 20px;
}
.button {
  background-color: #2196f3;
  color: white;
}
</style>
