<template>
  <div>
    <Modules />
  </div>
</template>

<script>
import Modules from "@/components/portal/Modules";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Modules,
  },
  metaInfo: {
    title: "Addons| flexplekken in Nederland ",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>
