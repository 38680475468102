var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticStyle:{"padding-top":"5rem"}},[_c('div',{staticClass:"text-center mb-8"},[_c('v-btn',{attrs:{"color":"deep-purple","outlined":""},on:{"click":function($event){_vm.active = !_vm.active}}},[_vm._v(" "+_vm._s(_vm.active ? "Menu verbergen" : "Menu laten zien")+" ")])],1),(_vm.active)?_c('v-bottom-navigation',{staticStyle:{"margin-bottom":"15px"}},[_c('v-btn',{attrs:{"color":_vm.activeElement === 'Pending' ? '' : ''},on:{"click":function($event){_vm.activeElement = 'Pending'}}},[_c('span',[_vm._v("Wacht op goedkeuring")]),_c('v-icon',{attrs:{"color":_vm.activeElement === 'Pending' ? 'blue lighten-1' : ''}},[_vm._v("gavel")])],1),_c('v-btn',{attrs:{"color":_vm.activeElement === 'Approved' ? '' : ''},on:{"click":function($event){_vm.activeElement = 'Approved'}}},[_c('span',[_vm._v("Goedgekeurd")]),_c('v-icon',{attrs:{"color":_vm.activeElement === 'Approved' ? 'green lighten-1' : ''}},[_vm._v(" mdi-checkbox-marked-circle")])],1),_c('v-btn',{attrs:{"color":_vm.activeElement === 'Cancelled' ? '' : ''},on:{"click":function($event){_vm.activeElement = 'Cancelled'}}},[_c('span',[_vm._v("Gegeannuleerd")]),_c('v-icon',{attrs:{"color":_vm.activeElement === 'Cancelled' ? 'red darken-1' : ''}},[_vm._v("mdi-cancel")])],1)],1):_vm._e(),(_vm.activeElement === 'Pending')?_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Boeking opzoeken","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pendingAppointments,"search":_vm.search},scopedSlots:_vm._u([{key:"item.appointment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.appointment_date ? new Date(item.appointment_date).toLocaleDateString() : "")+" ")]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.approveOrCancelBooking(item.appointment_id, 'cancelled')}}},[_vm._v(" mdi-close-circle ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.approveOrCancelBooking(item.appointment_id, 'approved')}}},[_vm._v(" mdi-check-circle-outline ")])]}}],null,true)})],1):_vm._e(),(_vm.activeElement === 'Approved')?_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Boeking opzoeken","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.approvedAppointments,"search":_vm.search},scopedSlots:_vm._u([{key:"item.appointment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.appointment_date ? new Date(item.appointment_date).toLocaleDateString() : "")+" ")]}}],null,true)})],1):_vm._e(),(_vm.activeElement === 'Cancelled')?_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Boeking opzoeken","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cancelledAppointments,"search":_vm.search},scopedSlots:_vm._u([{key:"item.appointment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.appointment_date ? new Date(item.appointment_date).toLocaleDateString() : "")+" ")]}}],null,true)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }