<template>
  <v-container>
    <div class="spacing">
      <v-row>
        <v-col>
          <v-card>
            <v-row>
              <v-card-title class="title">Profiel gegevens</v-card-title>
            </v-row>

            <v-row class="justify-center">
              <v-col col="10" sm="10">
                <v-text-field
                  v-model="customerInfo.company"
                  outlined
                  label="Bedrijfsnaam"
                />
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col col="5" sm="5">
                <v-text-field
                  v-model="customerInfo.name"
                  outlined
                  label="Voornaam"
                />
              </v-col>
              <v-col col="5" sm="5">
                <v-text-field
                  v-model="customerInfo.phone"
                  outlined
                  label="Telefoonnummer"
                />
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col col="5" sm="5">
                <v-text-field
                  v-model="customerInfo.adres"
                  outlined
                  label="Adres"
                />
              </v-col>
              <v-col col="5" sm="5">
                <v-text-field
                  v-model="customerInfo.postcode"
                  outlined
                  label="Postcode"
                />
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col col="10" sm="10">
                <v-text-field
                  v-model="customerInfo.email"
                  outlined
                  label="Emailadres"
                />
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="10" sm="10">
                <v-btn
                  color="primary"
                  dark
                  block
                  elevation="2"
                  @click="updateProfile()"
                >
                  Gegevens updaten
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col>
          <v-card style="padding-bottom:4.7rem">
            <v-row>
              <v-card-title class="title">Wachtwoord wijzigen</v-card-title>
            </v-row>

            <v-row class="justify-center">
              <v-col col="10" sm="10">
                <v-text-field
                  v-model="form.oldPassword.value"
                  solo
                  label="Oud wachtwoord"
                  prepend-inner-icon="mdi-lock-question"
                ></v-text-field>
                <v-text-field
                  solo
                  label="Nieuw wachtwoord"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  :class="{
                    'is-valid':
                      form.newPassword.value.length > 0 &&
                      has_minimum_length &&
                      has_number &&
                      has_lowercase &&
                      has_uppercase &&
                      has_special,
                    'is-invalid':
                      form.newPassword.value.length > 0 &&
                      !(
                        has_minimum_length &&
                        has_number &&
                        has_lowercase &&
                        has_uppercase &&
                        has_special
                      ),
                  }"
                  v-model="form.newPassword.value"
                ></v-text-field>
                <template v-if="form.newPassword.value.length > 0">
                  <div class="text-danger small" v-show="!has_minimum_length">
                    - Het wachtwoord moet minstens 8 karakters lang zijn
                  </div>
                  <div class="text-danger small" v-show="!has_number">
                    - Het wachtwoord moet minimaak 1 cijfer bevatten
                  </div>
                  <div class="text-danger small" v-show="!has_lowercase">
                    - Het wachtwoord moet minimaal een kleinletter bevatten
                  </div>
                  <div class="text-danger small" v-show="!has_uppercase">
                    - Het wachtwoord moet minimaal een hoofdletter bevatten
                  </div>
                  <div class="text-danger small" v-show="!has_special">
                    - Het wachtwoord moet minimaal een van deze karakters
                    bevatten !?@#$%^&*().;:_-
                  </div>
                </template>

                <v-btn
                  block
                  color="primary"
                  :disabled="
                    !(
                      has_minimum_length &&
                      has_number &&
                      has_lowercase &&
                      has_uppercase &&
                      has_special
                    )
                  "
                >
                  wachtwoord wijzigen
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import apiUser from '../api/apiUser'
import firebase from "firebase";
export default {
  data() {
    return {
      customerInfo: [],
      show: false,
      CheckPassword: "",
      has_minimum_length: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      form: {
        newPassword: { value: "" },
        oldPassword: { value: "" },
      },
    };
    
  },
    async mounted() {
   apiUser.getCurrentUser().then(data => {
      this.customerInfo = data
    })

  },
  watch: {
    "form.newPassword.value"() {
      this.has_minimum_length = this.form.newPassword.value.length > 8;
      this.has_number = /\d/.test(this.form.newPassword.value);
      this.has_lowercase = /[a-z]/.test(this.form.newPassword.value);
      this.has_uppercase = /[A-Z]/.test(this.form.newPassword.value);
      this.has_special = /[!?@#$%^&*().;:_-]/.test(this.form.newPassword.value);
    },
  },
  methods: {
    resetForm: function() {
      this.form.newPassword.value = "";
      this.form.oldPassword.value = "";
    },
    async updateProfile() {
      await axios.put(
        `https://server.kantoorflex.nl/api/rest/v2/customer/update/profile`,
        this.customerInfo
      );

      this.$swal.fire("Gewijzigd!", "", "success");
    },

    async reathenticateUser(oldPassword) {
      const user = firebase.auth().currentUser;
      const cred = firebase.auth.EmailAuthProvider.credential(
        user.email,
        oldPassword
      );
      return user.reauthenticateWithCredential(cred);
    },
    async changeCredentials() {
      this.reathenticateUser(this.form.oldPassword.value)
        .then(() => {
          const user = firebase.auth().currentUser;
          const newPassword = this.form.newPassword.value;
          user
            .updatePassword(newPassword)
            .then(() => {
              this.$swal.fire("Wachtwoord is gewijzigd!", "", "success");
              this.resetForm();
            })
            .catch((error) => {
              this.$swal.fire(error.message, "", "error");
            });
        })
        .catch((error) => {
          this.$swal.fire(error.message, "", "error");
        });
    },
  },
};
</script>

<style scoped>
.spacing {
  margin-top: 50px;
}
.title {
  margin-left: 50px;
  margin-top: 30px;
}
</style>
