<template>
  <div>
    <Bookings />
  </div>
</template>

<script>
import Bookings from "@/components/portal/Bookings";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Bookings,
  },
  metaInfo: {
    title: "Overzicht reserveringen | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>
