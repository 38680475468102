<template>
  <div>
    <Account />
  </div>
</template>

<script>
import Account from "@/components/portal/Account";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Account,
  },
  metaInfo: {
    title: "Mijn account | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>
